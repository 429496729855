import React from "react"
import { Link } from "gatsby"
import Carousel from 'nuka-carousel'
import Layout from "../components/layout"
import SEO from "../components/seo"

import AnneFrank from '../images/anne-frank.jpg'
import Gorbachev from '../images/gorbachev.jpg'
import LechWalesa from '../images/lech-walesa.jpg'
import ShimonPeres from '../images/shimon-peres.jpg'
import ShindlersList from '../images/shindlers-list.jpg'
import ShirinEbadi from '../images/shirin-ebadi.jpg'
import StevenHawking from '../images/steven-hawking.jpg'
import JaneMayer from '../images/JaneMayer.jpg'


const IndexPage = () => (
  
  <>
  <Layout>
    <SEO title="Home" />


    <div>
      <h2>welcome</h2>
      <Carousel className="carousel" autoplay={true} wrapAround={true} style={{margin: '0 0 25px 25px', border: '5px solid white', boxShadow: '0 0 15px rgba(0,0,0,.5)'}} transitionMode="fade"
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null }
      renderBottomCenterControls={() => null}
      >
        <img src={AnneFrank} alt="Anne Frank" />
        <img src={Gorbachev} alt="Mikhail Gorbachev" />
        <img src={LechWalesa} alt="Lech Walesa" />
        <img src={ShimonPeres} alt="Shimon Peres" />
        <img src={ShindlersList} alt="Shindler's List" />
        <img src={ShirinEbadi} alt="Shirin Ebadi" />
        <img src={StevenHawking} alt="Steven Hawking" />
      </Carousel>
      <p>Our clients have honored MKL Public Relations by entrusting the agency with meaningful assignments and exciting projects. We consider their trust and expectations an honor and seek relentlessly to honor them in return by creating effective communications and successful results upon their behalf. From creating annual events that continue year after year, such as the Vivaldi Candlelight Concert, the Governor’s Artist Series, and the McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism; taking a first-time author to number one on the New York Rimes best-seller list, both hardback and softback; providing a public relations perspective to attorneys during litigation where cases were either won or settled but never lost; organizing month-long events, tours, exhibits, and publicity for the Anne Frank International Photographic Exhibit, or presenting lectures that attract turn-away audiences for Stephen Hawking or Nobel Peace Prize laureates Shimon Peres, Shirin Ebadi, and Lech Walesa, we consider client trust in our work an exceptional privilege&hellip; and an immense pleasure.</p>
      {/* <div className="homepage-banner" style={{textAlign: 'center'}}>
        <Link to="/lecture-series-video">McCarthey Family Foundation 2020 Lecture<br />Saturday, November 7, 2020 at 7:00 p.m. MT.</Link>
        <br />
        <Link to="/lecture-series-video"><img src={JaneMayer} alt="Jane Mayer" width="300" style={{margin: "0 auto", padding: 0, border: "2px solid black"}}  />
        <div style={{margin: '0 auto', padding: 0}}>Jane Mayer</div>
        </Link>
      </div> */}
      
      <ul>
        <li><Link to="/mccarthey-family-foundation-lecture-series">McCarthey Family Foundation Lecture Series</Link></li>
        <li><Link to="/national-student-essay-competition">2019-2020 National Student Essay Competition</Link></li>
        <li><Link to="/todays-students-tomorrows-america">2024-2025 Today&rsquo;s Students, Tomorrow&rsquo;s America Initiative: National Student Essay Competition “Why Democracy Matters”</Link></li>
      </ul>

      <div style={{clear: 'both'}} />
      {/* <Image /> */}
    </div>
  </Layout>
  </>
)

export default IndexPage
